import { logEvent } from '@aa/helpers/analytics-events';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Feature, Feature as OpenLayerFeature, Map as OpenLayerMap, Overlay, View } from 'ol';
import { applyStyle } from 'ol-mapbox-style';
import { Attribution, defaults as defaultControls } from 'ol/control';
import Zoom from 'ol/control/Zoom';
import { Coordinate } from 'ol/coordinate';
import { easeOut } from 'ol/easing';
import { EventsKey } from 'ol/events';
import { altKeyOnly, click, pointerMove } from 'ol/events/condition';
import { boundingExtent, createEmpty as createEmptyExtent, extend } from 'ol/extent';
import { MVT } from 'ol/format';
import Point from 'ol/geom/Point';
import {
  DoubleClickZoom,
  DragPan,
  DragZoom,
  Interaction,
  KeyboardPan,
  KeyboardZoom,
  MouseWheelZoom,
  PinchZoom
} from 'ol/interaction';
import Select, { SelectEvent } from 'ol/interaction/Select';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import * as OlObservable from 'ol/Observable';
import { fromLonLat, transform } from 'ol/proj';
import { getVectorContext } from 'ol/render';
import RenderEvent from 'ol/render/Event';
import { Cluster, XYZ } from 'ol/source';
import VectorTileSource from 'ol/source/VectorTile';
import { Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { StyleFunction, StyleLike, toFunction } from 'ol/style/Style';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, shareReplay } from 'rxjs/operators';
import { MAP_TILE_DESCRIPTIONS, MapTileDescription, MapTileType } from '../map.module';

const TWO_FINGER_HINT = 'Use two fingers to move the map';
const ALT_SCROLL_HINT = 'Hold alt and scroll to zoom';

export enum MapLayer {
  None,
  Places,
  Boundary,
  Markers,
  TripLogs,
  CatchLogs,
  HighlightMarker,
  SponsoredMarkers,
  CreateMarker,
  SearchPlaces,
  NamedPoint,
  UserLocation,
  TripLocation,
  ModelCatchLogs,
  Tournaments,
  HighlightTournament,
  DragAndDrop,
  RCA,
  Subareas,
  Error,
  Metrics,

  GpsPoints,
  Accuracy,
  LiveTrack,
  Waterbodies,
  NearbyWaterbodies,
}

const zIndex: { [layer in MapLayer]: number; } = {
  [MapLayer.Boundary]: 1,
  [MapLayer.NearbyWaterbodies]: 1,
  //----------------------------------------
  [MapLayer.Metrics]: 2,
  [MapLayer.SearchPlaces]: 2,
  [MapLayer.TripLocation]: 2,
  [MapLayer.Waterbodies]: 2,
  //----------------------------------------
  [MapLayer.LiveTrack]: 3,
  //----------------------------------------
  [MapLayer.CatchLogs]: 4,
  [MapLayer.Markers]: 4,
  [MapLayer.Tournaments]: 4,
  [MapLayer.TripLogs]: 4,
  [MapLayer.UserLocation]: 4,
  //----------------------------------------
  [MapLayer.Accuracy]: 5,
  [MapLayer.HighlightMarker]: 5,
  [MapLayer.HighlightTournament]: 5,
  [MapLayer.Places]: 5,
  //----------------------------------------
  [MapLayer.GpsPoints]: 6,
  [MapLayer.SponsoredMarkers]: 6,
  //----------------------------------------
  [MapLayer.CreateMarker]: 10,
  [MapLayer.ModelCatchLogs]: 10,
  [MapLayer.NamedPoint]: 10,
  //----------------------------------------
  [MapLayer.DragAndDrop]: 1000,
  [MapLayer.Error]: 1000,
  [MapLayer.None]: 1000,
  [MapLayer.RCA]: 1000,
  [MapLayer.Subareas]: 1000,
};
export function zIndexFromMapLayer(layer: MapLayer): number {
  return zIndex[layer];
}

export enum MapOverlay {
  Places = 1,
  TripLogs,
  CatchLogs,
  Generic,
}

export enum MapAnimation {
  Blink,
}

type AddLayerOptions = {
  key: MapLayer;
  layer: VectorLayer<Feature>;
  onClick?: (feature: OpenLayerFeature) => void;
  deselectOnClick?: boolean;
  onHover?: (feature: OpenLayerFeature) => void;
  onDeselect?: () => void;
  interaction?: Interaction;
  onMoveend?: (extent: number[], map?: OpenLayerMap) => void;
  hoverStyle?: StyleLike;
  clickStyle?: (feature: OpenLayerFeature) => Style;
}

type AddTileLayerOptions = {
  key: MapLayer;
  layer: VectorTileLayer<Feature>;
  onClick?: (feature: Feature, coordinate: number[]) => void;
  deselectOnClick?: boolean;
}

@Component({
  selector: 'app-empty-map',
  templateUrl: './empty-map.component.html',
  styleUrls: ['./empty-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyMapComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked, OnChanges {
  static positions: { [key: string]: number[] } = {};

  // We need an initializer here to avoid a TS error. The value will be set in `ngAfterViewInit`.
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef = undefined!;
  @ViewChild('mapContainerElement', { static: true }) mapContainerElement: ElementRef = undefined!;
  @ViewChild('interactionHintElement', { static: true }) interactionHintElement: ElementRef = undefined!;

  @Input() additionalPadding = [0, 0, 0, 0];
  @Input() interactive = true;
  @Input() interactionRestrictionsEnabled = true;
  @Input() zoomPosition: 'top' | 'bottom' | 'none' = 'top';
  @Input() mapType: MapTileType = 'Roads';
  @Input() center?: number[];

  // TODO: lint check still needed?
  // tslint:disable-next-line:no-output-rename
  @Output('center') centerChanged: EventEmitter<number[] | null> = new EventEmitter();
  @Output() mapReady: EventEmitter<OpenLayerMap> = new EventEmitter();

  public highlightBody?: string;
  public loadingCounter = 0; // only set this through setters
  public interactionHintIsVisible = false;
  public interactionHint: string | null = null;
  public logo: SafeHtml | null = null;

  private hoverableLayers = new Set<Layer>();
  private onHovers: { [key: number]: (feature: OpenLayerFeature) => void } = {};
  private onClicks: {
    layer: VectorLayer<Feature>;
    action: (feature: OpenLayerFeature) => void; // action
    deselectOnClick: boolean; // deselect immediately after select
  }[] = [];
  private onTileClicks: {
    layer: VectorTileLayer<Feature>;
    action: (feature: Feature, coordinate: number[]) => void; // action
    deselectOnClick: boolean; // deselect immediately after select
  }[] = [];
  private onDeselects: { [key: number]: [() => void, VectorLayer<Feature>] } = {};
  private clickStyles: { [key: number]: [(feature: OpenLayerFeature) => Style, VectorLayer<Feature>] } = {};
  private hoverStyles = new Map<MapLayer, StyleFunction>();
  private onMoveends: ((extent: number[], map: OpenLayerMap) => void)[] = [];
  private additionalInteractions: Interaction[] = [];

  // init'd in `initMap()`
  public map: OpenLayerMap = undefined!;
  private clickInteraction: Select = undefined!;
  public tileLayer = new TileLayer();
  public vectorLayer = new VectorTileLayer({ declutter: true, zIndex: 10, visible: false });

  private hoverInteraction?: Select;
  private subscriptions: Subscription[] = [];
  private _isReady = new BehaviorSubject<boolean>(false);
  private mapTiles: { [key in MapTileType]: MapTileDescription & { rasterSource: XYZ; vectorSource?: VectorTileSource; }; };
  private hintTimeout: any;
  private totalTouches = 0;
  private positionKey: string = '';
  private interactionRestrictionsEventKeys: EventsKey[] = [];
  private interactionRestrictionsInteractions: Interaction[] = [];

  private layers: { [key: number]: VectorLayer<Feature> } = {};
  private additionalTileLayers: { [key: number]: VectorTileLayer<Feature> } = {};
  private zoomControl?: Zoom;

  private mapUpdateSizeSubject = new Subject<void>();
  private lastMapContainerElementWidth = -1;
  private lastMapContainerElementHeight = -1;

  private isReady = this._isReady.asObservable().pipe(
    filter((x) => x),
    shareReplay(1)
  );

  constructor(
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
  ) {
  }

  doOnReady(f: () => void) {
    this.isReady.subscribe({ next: () => f(), error: console.error });
  }

  updateSize() {
    if (this.map) {
      this.map.updateSize();
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.mapUpdateSizeSubject
        .pipe(debounceTime(100))
        .subscribe(() => this.zone.runOutsideAngular(() => this.map.updateSize()))
    );

    // TODO: add as @HostListener or w/e?
    document.addEventListener('fullscreenchange', () => {
      window.setTimeout(() => {
        this.interactionRestrictionsEnabled = !this.interactionRestrictionsEnabled;
        this.loadInteractionRestrictions();
        this.fitExtent();
      });
    });
  }

  ngAfterViewInit() {
    this.initMap();
    this._isReady.next(true);
    this.mapUpdateSizeSubject.next();
  }

  ngAfterViewChecked() {
    this.mapUpdateSizeIfChanged();
    this.updateZoomPadding();
  }

  private updateZoomPadding() {
    const el = this.mapContainerElement?.nativeElement?.querySelector('.ol-zoom');
    if (el) {
      const [top, right, bottom] = this.additionalPadding;
      const position = this.zoomPosition;
      const positions: [string, number][] = [];
      switch (position) {
        case 'top': {
          if (top) { positions.push(['top', top]); }
          if (right) { positions.push(['right', right]); }
          break;
        }
        case 'bottom': {
          if (bottom) { positions.push(['bottom', bottom]); }
          if (right) { positions.push(['right', right]); }
          break;
        }
      }

      for (const [style, value] of positions) {
        this.renderer.setStyle(el, style, `${value}px`);
      }
    }
  }

  ngOnDestroy() {
    clearTimeout(this.hintTimeout);
    this.subscriptions.forEach((it) => it.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this._isReady.value) {
      return;
    }

    if (changes['interactionRestrictionsEnabled']) {
      this.loadInteractionRestrictions();
    }

    if (changes['mapType']) {
      this.loadMapType();
    }

    if (changes['center']) {
      this.map.getView().setCenter(this.center);
    }
  }

  setMapType(type: MapTileType) {
    this.mapType = type;
    this.loadMapType();
  }

  getPosition(): number[] {
    return EmptyMapComponent.positions[this.positionKey]
  }

  calculateExtent() {
    return this.map.getView().calculateExtent();
  }

  fitExtent(keys?: MapLayer[]) {
    if (keys == undefined) {
      keys = Object.keys(this.layers)
        .map((key) => parseInt(key))
        .filter((key) => this.includedInFitExtent(key));
    }

    const extent = createEmptyExtent();
    for (const key of keys) {
      let source = this.layers[key]?.getSource();
      while (source && source instanceof Cluster) {
        source = source.getSource();
      }

      if (source) {
        extend(extent, source.getExtent());
      }
    }

    const goodExtent = extent.reduce((a, c) => a && isFinite(c) && !isNaN(c), true);

    this.mapUpdateSizeSubject.next();
    if (goodExtent) {
      this.map.getView().fit(this.padExtent(extent), {
        padding: [
          15 + this.additionalPadding[0],
          15 + this.additionalPadding[1],
          15 + this.additionalPadding[2],
          15 + this.additionalPadding[3],
        ],
      });
    }
  }

  launchIntoFullscreen() {
    const element = this.wrapper?.nativeElement;
    if (!element) return;

    logEvent('map_made_fullscreen');

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }

    window.setTimeout(() => {
      this.fitExtent();
    }, 1);
  }

  exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((<any>document).mozCancelFullScreen) {
      (<any>document).mozCancelFullScreen();
    } else if ((<any>document).webkitExitFullscreen) {
      (<any>document).webkitExitFullscreen();
    }
  }

  addLayer(options: AddLayerOptions) {
    const name = options.key;
    const z = zIndexFromMapLayer(options.key);

    if (!this.layers[name]) {
      this.layers[name] = options.layer;
      this.layers[name].setZIndex(z);
      if (options.onHover) {
        this.onHovers[name] = options.onHover;
        this.hoverableLayers.add(this.layers[name]);
      }
      if (options.onClick) {
        this.onClicks[name] = {
          layer: this.layers[name],
          action: options.onClick,
          deselectOnClick: options.deselectOnClick || false,
        };
      }

      if (options.onDeselect) {
        this.onDeselects[name] = [options.onDeselect, this.layers[name]];
      }

      if (options.interaction) {
        this.map.addInteraction(options.interaction);
        this.additionalInteractions[name] = options.interaction;
      }

      if (options.onMoveend) {
        this.onMoveends[name] = options.onMoveend;
      }

      if (options.clickStyle) {
        this.clickStyles[name] = [options.clickStyle, this.layers[name]];
      }

      if (options.hoverStyle) {
        this.hoverStyles.set(name, toFunction(options.hoverStyle));
      }
    }

    this.isReady.subscribe(() => {
      this.map.addLayer(this.layers[name]);
    });
  }

  addTileLayer(options: AddTileLayerOptions) {
    const name = options.key;
    const z = zIndexFromMapLayer(options.key);

    if (!this.additionalTileLayers[name]) {
      this.additionalTileLayers[name] = options.layer;
      this.additionalTileLayers[name].setZIndex(z);

      if (options.onClick) {
        this.onTileClicks[name] = {
          layer: this.additionalTileLayers[name],
          action: options.onClick,
          deselectOnClick: options.deselectOnClick || false,
        };
      }
    }

    this.isReady.subscribe(() => {
      this.map.addLayer(this.additionalTileLayers[name]);
    });
  }

  removeLayer(layer: MapLayer) {
    const name = layer;
    const mapLayer = this.layers[name];
    const interaction = this.additionalInteractions[name];

    if (mapLayer) {
      this.hoverableLayers.delete(this.layers[name]);
      delete this.additionalInteractions[name];
      delete this.onHovers[name];
      delete this.onClicks[name];
      delete this.onDeselects[name];
      delete this.onMoveends[name];
      delete this.layers[name];
      this.isReady.subscribe(() => {
        this.map.removeInteraction(interaction);
        this.map.removeLayer(mapLayer);
      });
    }
  }

  // does not need to be unsubscribed, as it will only ever return 1 value
  getLayer(layer: MapLayer): Observable<VectorLayer<Feature>> {
    return this.isReady.pipe(map(() => this.layers[layer]));
  }

  addOverlay(overlay: Overlay) {
    this.isReady.subscribe(() => {
      const id = overlay.getId();
      if (id && !this.map.getOverlayById(id)) {
        this.map.addOverlay(overlay);
      } else {
        console.warn(`Skipping adding overlay: "${id}"`);
      }
    });
  }

  removeOverlay(key: MapOverlay) {
    this.isReady.subscribe((_) => {
      const overlay = this.map.getOverlayById(key);
      this.map.removeOverlay(overlay);
    });
  }

  addInteraction(interaction: Interaction) {
    this.isReady.subscribe(_ => {
      this.map.addInteraction(interaction);
    });
  }

  removeInteraction(interaction: Interaction) {
    this.isReady.subscribe(_ => {
      this.map.removeInteraction(interaction);
    });
  }

  // does not need to be unsubscribed, as it will only ever return 1 value
  getOverlay(key: MapOverlay): Observable<Overlay> {
    return this.isReady.pipe(map(() => this.map.getOverlayById(key)));
  }

  addLoading() {
    this.loadingCounter++;
    this.cdr.detectChanges();
  }

  removeLoading() {
    this.loadingCounter--;
    this.cdr.detectChanges();
  }

  addAnimation(_: MapAnimation, fn: (event: RenderEvent) => void) {
    this.map.on('postcompose', fn);
  }

  removeAnimation() {
    this.loadingCounter--;
    this.cdr.detectChanges();
  }

  zoomIn() {
    const view = this.map.getView();
    let zoom = (view.getZoom() ?? view.getMaxZoom()) + 1;
    if (zoom > view.getMaxZoom()) {
      zoom = view.getMaxZoom();
    }
    this.zoom(zoom);
  }

  zoomOut() {
    const view = this.map.getView();
    let zoom = (view.getZoom() ?? view.getMinZoom()) - 1;
    if (zoom < view.getMinZoom()) {
      zoom = view.getMinZoom();
    }
    this.zoom(zoom);
  }

  clearSelection() {
    this.clickInteraction.getFeatures().clear();
  }

  on(event: any, f: (e: any) => void) {
    this.isReady.subscribe(() => this.map.on(event, f));
  }

  getCenter(): Coordinate | undefined {
    const map = this.map;
    const view = map?.getView();
    const center = view?.getCenter();
    const transformed = transform(center, 'EPSG:3857', 'EPSG:4326');
    return transformed;
  }

  setCenter(center: Coordinate) {
    this.isReady.subscribe(() => {
      const map = this.map;
      const view = map.getView();
      view.setCenter(center);
    });
  }

  render() {
    this.map.render();
  }

  addBlink(feature: OpenLayerFeature, layer: VectorLayer<Feature>): EventsKey | null {
    const geom = feature.getGeometry();
    if (!geom) return null;

    const duration = 1500;
    let start = new Date().getTime();

    const animate = (event: RenderEvent) => {
      const frameState = event.frameState;
      if (frameState) {
        const elapsed = frameState.time - start;
        const elapsedRatio = elapsed / duration;
        // radius will be 5 at start and 30 at end.
        const radius = easeOut(elapsedRatio) * 25 + 5;
        const opacity = easeOut(1 - elapsedRatio);

        const vectorContext = getVectorContext(event);
        vectorContext.setStyle(
          new Style({
            image: new CircleStyle({
              radius: radius,
              stroke: new Stroke({
                color: 'rgba(255, 0, 0, ' + opacity + ')',
                width: 0.25 + opacity,
              }),
            }),
            zIndex: 1,
          })
        );
        vectorContext.drawGeometry(geom);

        if (elapsed > duration) {
          start = new Date().getTime();
        }

        // tell OpenLayers to continue postcompose animation
        this.map.render();
      }
    };

    return layer.on('postrender', animate);
  }

  private zoom(level: number) {
    this.map.getView().animate({
      zoom: level,
      duration: 200,
    });
  }

  private padExtent(extent: number[]) {
    const padX = Math.abs(extent[0] - extent[2]) * 0.05 || 1000;
    const padY = Math.abs(extent[1] - extent[3]) * 0.05 || 1000;
    return [extent[0] - padX, extent[1] - padY, extent[2] + padX, extent[3] + padY];
  }

  private includedInFitExtent(layer: number): boolean {
    switch (layer) {
      case MapLayer.Places:
      case MapLayer.Boundary:
      case MapLayer.TripLogs:
      case MapLayer.CatchLogs:
      case MapLayer.ModelCatchLogs:
      case MapLayer.Tournaments:
      case MapLayer.DragAndDrop:
        return true;
    }
    return false;
  }

  private mapUpdateSizeIfChanged() {
    if (this.mapContainerElement && this.mapContainerElement.nativeElement) {
      const width = this.mapContainerElement.nativeElement.offsetWidth;
      const height = this.mapContainerElement.nativeElement.offsetHeight;
      if (width !== this.lastMapContainerElementWidth || height !== this.lastMapContainerElementHeight) {
        this.lastMapContainerElementWidth = width;
        this.lastMapContainerElementHeight = height;
        this.mapUpdateSizeSubject.next();
      }
    } else {
      this.mapUpdateSizeSubject.next();
    }
  }

  private showInteractionHint(message: string) {
    if (!this.interactionHintIsVisible) {
      logEvent('map_interaction_blocked')
    }
    const changeRequired = message !== this.interactionHint;
    this.interactionHint = message;
    this.interactionHintIsVisible = true;
    clearTimeout(this.hintTimeout);
    this.hintTimeout = setTimeout(() => {
      this.hintTimeout = null;
      this.hideInteractionHint();
    }, 2000);

    if (changeRequired) {
      this.cdr.detectChanges();
    }
  }

  private hideInteractionHint() {
    this.interactionHint = null;
    this.interactionHintIsVisible = false;
    this.cdr.detectChanges();
  }

  private initMap() {
    applyStyle(
      this.vectorLayer,
      'mapbox://styles/anglersatlas/clmqkma21004t01rdhu3bakxu',
      { accessToken: 'pk.eyJ1IjoiYW5nbGVyc2F0bGFzIiwiYSI6ImNsbWY0MHIxYTAyMHczbGxscnljMmRpeDQifQ.ZNYTlk9J1OUueH_tR7-sAQ' },
    );

    const mapTileFromMapTileType = (type: MapTileType) => {
      const desc = MAP_TILE_DESCRIPTIONS[type];
      const rasterSource = new XYZ({ url: desc.url, attributions: desc.attributions });
      const vectorSource = desc.vectorUrl ? new VectorTileSource({ url: desc.vectorUrl, format: new MVT() }) : null;
      const mapTile = { ...desc, rasterSource, vectorSource };
      return mapTile;
    }

    this.mapTiles = {
      'Roads': mapTileFromMapTileType('Roads'),
      'Bathymetry': mapTileFromMapTileType('Bathymetry'),
      'Satellite': mapTileFromMapTileType('Satellite'),
    };
    for (const type in this.mapTiles) {
      const { name, rasterSource } = this.mapTiles[type];
      rasterSource.on('tileloadend', () => logEvent('map_tile_loaded', { name }));
    }

    if (!this.mapType) {
      this.mapType = 'Roads';
    }

    const hoverFilter = (_: Feature, layer: Layer) => this.hoverableLayers.has(layer);
    const onHover = (evt: SelectEvent) => {
      const feature = evt.selected[0];

      for (let i in this.onHovers) {
        this.onHovers[i](feature);
      }
    };

    const clickFilter = (_: Feature, layer: Layer) => {
      for (let i in this.onClicks) {
        if (this.onClicks[i].layer === layer) {
          return true;
        }
      }
      return false;
    };
    const onClick = (evt: SelectEvent) => {
      if (!evt.selected.length && !evt.deselected.length) {
        return;
      }

      this.map.getOverlays().forEach((o) => o.setPosition(undefined));

      const feature = evt.selected && evt.selected[0];
      const features = feature && feature.get('features');

      if (features && features.length > 1) {
        const geometries = features.map((f: OpenLayerFeature) => {
          return (<Point>f.getGeometry()).getCoordinates();
        });

        this.map.getView().fit(boundingExtent(geometries), {
          padding: [60, 15, 15, 15],
        });
        evt.target.getFeatures().clear();
        return;
      }

      if (feature) {
        for (let i in this.onClicks) {
          const source = this.onClicks[i].layer.getSource();
          if (source && source.getFeatures().includes(feature)) {
            this.onClicks[i].action(feature);
            if (this.onClicks[i] && this.onClicks[i].deselectOnClick) {
              evt.target.getFeatures().clear();
            }
          }
        }
      }
    };

    const attribution = new Attribution({
      collapsible: false,
    });

    const controls = defaultControls({ attribution: false, zoom: false }).extend([attribution]);
    const interactions: Interaction[] = [];

    if (this.interactive) {
      this.zoomControl = new Zoom({
        className: 'ol-zoom ' + this.zoomPosition,
      });
      if (this.zoomPosition !== 'none') {
        controls.push(this.zoomControl);
      }

      interactions.push(new DoubleClickZoom(), new PinchZoom(), new KeyboardPan(), new KeyboardZoom(), new DragZoom());
    }

    this.map = new OpenLayerMap({
      target: this.mapContainerElement.nativeElement,
      layers: [this.tileLayer, this.vectorLayer],
      view: new View({
        center: fromLonLat([-100, 47]),
        zoom: 4,
        constrainResolution: true,
      }),
      controls: controls,
      interactions: interactions,
    });
    this.mapReady.emit(this.map);

    logEvent('map_loaded');

    if (this.interactive) {
      this.map.on('click', ({ map, pixel, coordinate }) => {
        logEvent('map_clicked');

        for (let i in this.onDeselects) {
          const [onDeselect, layer] = this.onDeselects[i];
          const source = layer.getSource();
          if (!source) continue;

          const features = source.getFeatures();
          const clickedFeatures = map.getFeaturesAtPixel(pixel);

          let featureHit = false;
          for (const feature of clickedFeatures) {
            featureHit ||= feature instanceof OpenLayerFeature && features.includes(feature);
          }

          if (!featureHit) {
            onDeselect();
          }
        }

        for (const name in this.onTileClicks) {
          const { action, layer } = this.onTileClicks[name];
          layer.getFeatures(pixel).then((features) => {
            for (const feature of features) {
              action(feature as Feature, coordinate);
            }
          });
        }
      });

      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
      let hasTouchScreen = false;
      const navigator: any = window.navigator;
      if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
      } else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
      } else {
        const mQ = matchMedia?.("(pointer:coarse)");
        if (mQ?.media === "(pointer:coarse)") {
          hasTouchScreen = !!mQ.matches;
        } else if ("orientation" in window) {
          hasTouchScreen = true; // deprecated, but good fallback
        } else {
          // Only as a last resort, fall back to user agent sniffing
          const UA = 'userAgent' in navigator ? navigator.userAgent : '';
          hasTouchScreen =
            /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
            /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
        }
      }

      if (hasTouchScreen) {
        this.clickInteraction = new Select({
          condition: click,
          filter: (feature, layer) => {
            return hoverFilter(feature, layer) || clickFilter(feature, layer);
          },
          hitTolerance: 10,
          style: (feature) => {
            if (!(feature instanceof OpenLayerFeature)) {
              return new Style();
            }

            for (let i in this.clickStyles) {
              const source = this.clickStyles[i][1].getSource();
              const features = source?.getFeatures() ?? [];
              if (features.includes(feature)) {
                return this.clickStyles[i][0](feature);
              }
            }

            // No selected style, leave to marker style function
            return new Style();
          },
        });

        let selectedFeature: Feature | null = null;
        let cooldown = 0;
        this.clickInteraction.on('select', (evt) => {
          logEvent('map_clicked');
          cooldown = new Date().getTime() + 200;

          const layers = this.layers;
          const selected = evt.selected;
          const firstSelected = selected.length ? selected[0] : null;

          // TODO: this, uh, I don't see how this works... I don't see how this ever worked...
          const shouldHover = firstSelected &&
            (
              (layers['top']?.getSource()?.hasFeature(firstSelected)) ||
              (layers['places']?.getSource()?.hasFeature(firstSelected)) ||
              (layers['searchPlaceFunc']?.getSource()?.hasFeature(firstSelected))
            );
          if (!selectedFeature && shouldHover) {
            selectedFeature = firstSelected;
            onHover(evt);
          } else {
            onClick(evt);
          }
        });

        this.map.on('click', (evt) => {
          const map = evt.map;

          let featureClicked = false;
          map.forEachFeatureAtPixel(evt.pixel, (feature) => {
            featureClicked ||= (feature === selectedFeature);
          });
          if (!featureClicked) {
            selectedFeature = null;
          }

          map.getOverlays().forEach((o) => o.setPosition(undefined));
        });

        this.map.on('movestart', () => {
          if (new Date().getTime() > cooldown) {
            selectedFeature = null;
            this.map.getOverlays().forEach((o) => o.setPosition(undefined));
          }
        });

        this.map.addInteraction(this.clickInteraction);
      } else {
        this.hoverInteraction = new Select({
          condition: pointerMove,
          filter: hoverFilter,
          style: (feature) => {
            const layerId: MapLayer = feature.get('layer-id') ?? MapLayer.None;

            //- cg: get the hover style for this feature's layer
            let styleFunc = this.hoverStyles.get(layerId);
            //- cg: if the layer wasn't found, check if this feature is one of the hover style's features
            if (!styleFunc && feature instanceof OpenLayerFeature) {
              for (const [id, style] of this.hoverStyles) {
                const layer = this.layers[id];
                const source = layer?.getSource();
                const features = source?.getFeatures() ?? [];
                if (features.includes(feature)) {
                  styleFunc = style;
                  break;
                }
              }
            }
            //- cg: otherwise, default to the layer's style or the feature's style
            styleFunc = styleFunc ?? this.layers[layerId]?.getStyleFunction() ?? feature.getStyleFunction();

            //- cg: avoid infinite recursion
            if (styleFunc == this.hoverInteraction.getStyle()) {
              styleFunc = null;
            }

            const view = this.map.getView();
            const resolution = view.getResolution();
            const style = styleFunc ? styleFunc(feature, resolution) : null;

            // TODO(cg): When returning null, this clears the style instead of defaulting to the marker's or layer's style...
            return style;
          },
        });
        this.hoverInteraction.on('select', onHover);

        this.clickInteraction = new Select({
          condition: click,
          filter: clickFilter,
          style: (feature) => {
            if (!(feature instanceof OpenLayerFeature)) {
              return null;
            }

            for (let i in this.clickStyles) {
              const source = this.clickStyles[i][1].getSource();
              const features = source?.getFeatures() ?? [];
              if (features.includes(feature)) {
                return this.clickStyles[i][0](feature);
              }
            }

            // No selected style, leave to marker style function
            return null;
          },
        });

        this.clickInteraction.on('select', onClick);

        this.map.addInteraction(this.hoverInteraction);
        this.map.addInteraction(this.clickInteraction);
      }

      this.loadInteractionRestrictions();
    }

    this.map.getView().setMaxZoom(18);
    this.map.getView().setMinZoom(0);

    this.positionKey = window.location.href;
    if (EmptyMapComponent.positions[this.positionKey]) {
      const [x, y, z] = EmptyMapComponent.positions[this.positionKey];
      const view = this.map.getView();
      view.setCenter([x, y]);
      view.setZoom(z);
    }

    let firstLogIgnored = false;
    this.map.on('moveend', (evt) => {
      if (firstLogIgnored) {
        logEvent('map_moved');
      }
      firstLogIgnored = true;
      const map = evt.map;
      const view = map.getView();
      const zoom = view.getZoom();
      const center = view.getCenter();
      if (center && zoom) {
        this.zone.run(() => {
          EmptyMapComponent.positions[this.positionKey] = [center[0], center[1], zoom];
        });

        const transformedCenter = transform(center, 'EPSG:3857', 'EPSG:4326');
        this.centerChanged.emit(transformedCenter);

        const extent = view.calculateExtent(map.getSize());
        this.onMoveends.forEach((onMoveend) => {
          onMoveend(extent, map);
        });
      }
    });

    this.loadMapType();
  }

  private loadMapType() {
    const tile = this.mapTiles[this.mapType];

    this.logo = tile.logo ? this.sanitizer.bypassSecurityTrustHtml(tile.logo) : null;
    this.tileLayer.setSource(tile.rasterSource);
    this.vectorLayer.setSource(tile.vectorSource);

    this.vectorLayer.setVisible(tile.vectorSource ? true : false);

    this.cdr.markForCheck();
  }

  private loadInteractionRestrictions() {
    for (const i of this.interactionRestrictionsInteractions) {
      this.map.removeInteraction(i);
    }
    this.interactionRestrictionsInteractions.length = 0;

    for (const key of this.interactionRestrictionsEventKeys) {
      OlObservable.unByKey(key);
    }
    this.interactionRestrictionsEventKeys.length = 0;

    if (this.interactionRestrictionsEnabled) {
      let clientY: number | null = null;
      let firstTouch: number[] | null = null;

      const pointerdown = this.map.on(<any>'pointerdown', () => {
        this.totalTouches += 1;
        this.cdr.detectChanges();
        if (this.totalTouches === 2) {
          firstTouch = null;
        }
      });

      const pointerup = this.map.on(<any>'pointerup', () => {
        this.totalTouches -= 1;
        this.cdr.detectChanges();
        clientY = null;
        firstTouch = null;
      });

      const pointermove = this.map.on('pointermove', (e) => {
        const orig = e.originalEvent;
        if ((<any>window).PointerEvent && orig instanceof PointerEvent) {
          if (orig.pointerType === 'mouse') {
            return;
          }
          if (this.totalTouches !== 2) {
            if (clientY !== null) {
              const delta = orig.clientY - clientY;
              const el = document.scrollingElement;
              if (el) {
                const scroll = el.scrollTop - delta;
                el.scrollTop = scroll;
              }
            }
            if (!firstTouch) {
              firstTouch = [orig.clientX, orig.clientY];
            }
            const deltaX = orig.clientX - firstTouch[0];
            const deltaY = orig.clientY - firstTouch[1];
            if (Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2)) > 20) {
              this.showInteractionHint(TWO_FINGER_HINT);
            }
            clientY = orig.clientY;
          }
        }
      });

      const dragPan = new DragPan({
        condition: (olBrowserEvent) => {
          const originalEvent = olBrowserEvent.originalEvent;
          if ((<any>window).TouchEvent && originalEvent instanceof TouchEvent) {
            if (originalEvent.touches.length === 2) {
              if (this.interactionHint === TWO_FINGER_HINT) {
                this.hideInteractionHint();
              }
              return true;
            } else {
              return false;
            }
          }

          if ((<any>window).PointerEvent && originalEvent instanceof PointerEvent) {
            const allowed =
              this.totalTouches === 2 ||
              (originalEvent instanceof PointerEvent && originalEvent.pointerType === 'mouse');

            if (allowed) {
              if (this.interactionHint === TWO_FINGER_HINT) {
                this.hideInteractionHint();
              }
              return true;
            } else {
              return false;
            }
          }

          return true;
        },
      });

      const mouseWheelZoom = new MouseWheelZoom({
        condition: (olBrowserEvent) => {
          if (olBrowserEvent.type === 'wheel') {
            if (altKeyOnly(olBrowserEvent) !== true) {
              this.showInteractionHint(ALT_SCROLL_HINT);
              return false;
            }
            if (this.interactionHint === ALT_SCROLL_HINT) {
              this.hideInteractionHint();
            }
          }
          return true;
        },
      });

      if (Array.isArray(pointerdown)) {
        this.interactionRestrictionsEventKeys.push(...pointerdown);
      } else {
        this.interactionRestrictionsEventKeys.push(pointerdown);
      }
      if (Array.isArray(pointerup)) {
        this.interactionRestrictionsEventKeys.push(...pointerup);
      } else {
        this.interactionRestrictionsEventKeys.push(pointerup);
      }
      if (Array.isArray(pointermove)) {
        this.interactionRestrictionsEventKeys.push(...pointermove);
      } else {
        this.interactionRestrictionsEventKeys.push(pointermove);
      }
      this.interactionRestrictionsInteractions.push(dragPan, mouseWheelZoom);
      this.map.addInteraction(dragPan);
      this.map.addInteraction(mouseWheelZoom);
    } else {
      const dragPan = new DragPan();
      const mouseWheelZoom = new MouseWheelZoom();
      this.interactionRestrictionsInteractions.push(dragPan, mouseWheelZoom);
      this.map.addInteraction(dragPan);
      this.map.addInteraction(mouseWheelZoom);
    }
  }
}

