import { InjectionToken, ModuleWithProviders, NgModule, Provider, runInInjectionContext } from '@angular/core';
import { AuthenticationService } from './authentication.service';

export const CLIENT_ID = new InjectionToken<string>('aa-authentication-client-id');
export const CLIENT_SECRET = new InjectionToken<string>('aa-authentication-client-secret');

@NgModule()
export class AuthenticationModule {
  static forRoot(clientId: string, clientSecret: string): ModuleWithProviders<AuthenticationModule> {
    const clientIdProvider: Provider = { provide: CLIENT_ID, useValue: clientId };
    const clientSecretProvider: Provider = { provide: CLIENT_SECRET, useValue: clientSecret };
    return {
      ngModule: AuthenticationModule,
      providers: [
        clientIdProvider,
        clientSecretProvider,
        AuthenticationService,
      ],
    };
  }
}
