<div #wrapper class="wrapper">

  <div #mapContainerElement class="map"></div>

  @if (logo) { <div class="logo" [innerHTML]="logo"></div> }

  @if (!interactive) { <div class="blocker"></div> }

  <div class="interaction-hint valign-wrapper center-align"
       [class.visible]="interactionHintIsVisible"
       #interactionHintElement
       draggable="false">
    <div class="hint-text white-text">{{ interactionHint }}</div>
  </div>

  @if (loadingCounter > 0) {
    <div class="progress">
      <div class="indeterminate"></div>
    </div>
  }

  <ng-content></ng-content>
</div>

