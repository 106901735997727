import { latLngFromGeoJsonPoint, staticMapPathParamFromGeoJson } from '@aa/helpers/geo';
import { clone, cloneArray } from '@aa/helpers/objects';
import { Tournament } from '@aa/models/tournament';
import { Icon, Style } from 'ol/style';

export const LOGICAL_BOUNDARY_TYPES = [
  'CONSERVATION_AREA',
  'MANAGEMENT_SUBAREA',
  'REGION',
  'MANAGEMENT_AREA',
  'COUNTY',
  'DISTRICT',
  'PROVINCE',
  'STATE',
  'TERRITORY',
  'COUNTRY',
  'DISEASE_REGION',
  'SUBREGION',
  'CUSTOM',
] as const;
export type LOGICAL_BOUNDARY_TYPE = typeof LOGICAL_BOUNDARY_TYPES[number];

export const LOGICAL_BOUNDARY_SUPERTYPES = [
  'COUNTRY',
  'ADMINISTRATIVE_AREA_1',
  'ADMINISTRATIVE_AREA_2',
  'MANAGEMENT_UNIT',
  'MANAGEMENT_SUBUNIT',
  'NON_HIERARCHICAL',
] as const;
export type LOGICAL_BOUNDARY_SUPERTYPE = typeof LOGICAL_BOUNDARY_SUPERTYPES[number];

export const PLACE_SUPERTYPE_WATERBODY = 'WATERBODY';

export type Within = {
  id: number;
  name: string;
  slug: string;
};

export type AccessNote = {
  id: number;
  created_at: Date;
  updated_at: Date;
  status: string;
  note: string;
};

export type RecSite = {
  id: number;
  created_at: Date;
  updated_at: Date;
  official_name: string;
  location: string;
  description: string;
  directions: string;
  accessibility: string;
  activities: string;
  facilities: string;
};

export type Place = {
  id: number;
  created_at?: Date;
  updated_at?: Date;
  type: string;
  name: string;
  alias_count?: number;
  aliases?: string[];
  centroid: string;
  envelope: string;
  provinces?: string[];
  is_followed?: boolean;
  boundary?: string;
  km_to?: number;
  access_notes?: AccessNote[];
  rec_sites?: RecSite[];
  within?: {
    REGION?: Within[];
    PROVINCE?: Within[];
    STATE?: Within[];
    COUNTRY?: Within[];
  };
  coordinates?: string;
  area?: {
    ha?: number;
    ac?: number;
    km?: number;
    m?: number;
  };
  has_depth_chart: boolean;
  supertype: string;
  structured_data?: string;
  detailed_description?: string;
  top_description?: any;
  slug: string;
  catch_rate?: number;
  tournaments?: Tournament[];
  simple_within?: string;

  // NOTE: Needs to be initialized by the user ( PlaceService.urlTo(place) )
  urlTo?: any[];
};

export type PlaceSearchResult = {
  id: number;
  name: string;
  slug: string;
  aliases: string;
  type: string;
  preview: {
    latitude: number;
    longitude: number;
  };
  within: string;
  species: any[];
  boat_launches: number;
  rec_sites: number;
  photo_url: string;
  overview: string;
  number_of_contour_maps: number;

  // set locally not from server
  urlTo: string[];

  // deprecated (not sent from server)
  supertype?: string;
  has_depth_chart?: boolean;
  countries?: string;
  regions?: string;
  administrative_divisions?: string;
  envelope?: {
    south: number;
    north: number;
    east: number;
    west: number;
  };
};

export type SuggestedPlace = {
  id: number;
  name: string;
  centroid: string;
  envelope: string;
  within: {
    REGION?: Within[];
    PROVINCE?: Within[];
    STATE?: Within[];
    COUNTRY?: Within[];
  };
  description: string;
  photo_url: string;
  photo_author_username: string;
  map_count: number;
  slug: string;
  is_sponsored: boolean;
};

export type Town = {
  name: string;
  province_code: string | null;
};

//------------------------------------------------------------------------------

export function clonePlace(place: Place | undefined | null): Place | undefined | null {
  const result = clone(place);
  if (place && result) {
    result.aliases = cloneArray(place.aliases) ?? [];
    result.provinces = cloneArray(place.provinces) ?? [];
    result.access_notes = cloneArray(place.access_notes) ?? [];
    result.rec_sites = cloneArray(place.rec_sites) ?? [];
    if (place.within) {
      result.within = {
        REGION: cloneArray(place.within.REGION)!,
        PROVINCE: cloneArray(place.within.PROVINCE)!,
        STATE: cloneArray(place.within.STATE)!,
        COUNTRY: cloneArray(place.within.COUNTRY)!,
      };
    }
    result.area = clone(place.area);
  }
  return result;
}

//------------------------------------------------------------------------------

export function isPlace(place: Place | PlaceSearchResult | SuggestedPlace | null): place is Place {
  return place && ('centroid' in place || 'envelope' in place);
}

const iconCache = new Map<string, Style>();

export function getIcon(
  place: { is_sponsored?: boolean; } | { supertype?: string; type?: string; },
  small: boolean = false,
): Style {
  let src = small ? '/assets/markers/small-marker.svg' : '/assets/markers/waterbody.svg';
  if ('is_sponsored' in place && place.is_sponsored) {
    src = '/assets/markers/waterbody-sponsored.svg';
  } else if ('supertype' in place) {
    switch (place.supertype) {
      // NOTE: default waterbody case already handled at declaration
      case 'WATERBODY': break;
      case 'CITY':
        src = '/assets/markers/city.svg';
        break;
      case 'REGION':
        src = '/assets/markers/region.png';
        break;
      case 'ADMINISTRATIVE_DIVISION':
        switch (place.type) {
          case 'PROVINCE':
            src = '/assets/markers/province.png';
            break;
          case 'STATE':
            src = '/assets/markers/state.png';
            break;
        }
        break;
      case 'COUNTRY':
        src = '/assets/markers/country.png';
    }
  }

  const style = iconCache.get(src) ?? new Style({ image: new Icon({ src, anchor: [0.5, 1.0] }) });
  return style;
}

export function getOffset(
  place: Place | SuggestedPlace | PlaceSearchResult,
  small = false
): [number, number] {
  let result: [number, number] = small ? [0, -27] : [0, -30];

  if ('supertype' in place) {
    switch (place.supertype) {
      // NOTE: handled at result declaration
      case 'WATERBODY': break;
      case 'CITY': result = [0, -30]; break;
      case 'REGION': result = [0, -56]; break;
      case 'ADMINISTRATIVE_DIVISION': result = [0, -56]; break;
      case 'COUNTRY': result = [0, -56]; break;
    }
  }

  return result;
}

/** An atom (non-divisible) place */
export function isAtomPlace(place: Place): boolean {
  return place.type !== 'REGION' && place.type !== 'STATE' && place.type !== 'PROVINCE' && place.type !== 'COUNTRY';
}

export function placeNameWithProvinceOrState(place: Place): string {
  const subtitle = placeSubtitle(place);
  if (subtitle) {
    return `${place.name}, ${subtitle}`;
  }
  return place.name;
}

export function placeSubtitle(place: Place): string | null {
  if (place.within) {
    if (place.within.PROVINCE) {
      return place.within.PROVINCE[0].name;
    } else if (place.within.STATE) {
      return place.within.STATE[0].name;
    }
  }

  return null;
}

export function placeLongSubtitle(place: { within?: Place['within']; }): string | null {
  if (place.within) {
    let result: string[] = [];
    const addWithin = (w: Within[] | null | undefined) => {
      if (!w) return;
      for (const it of w) result.push(it.name);
    };
    addWithin(place.within.COUNTRY);
    addWithin(place.within.PROVINCE);
    addWithin(place.within.STATE);
    addWithin(place.within.REGION);

    return result.join(', ');
  }

  return null;
}

export function staticMapUrlFromPlace(
  place: Place,
  width: number,
  height: number,
  baseUrl: string,
): string {
  let endpoint = 'auto';
  let params = [
    'latlng=1',
  ];

  const [lat, lon] = latLngFromGeoJsonPoint(place.centroid);

  if (place.envelope) {
    const path = staticMapPathParamFromGeoJson(place.envelope);
    params.push(path);
  } else {
    endpoint = `${lon},${lat},10`;
  }

  // TODO: @2x retina

  let url = `${baseUrl}/${endpoint}/${width}x${height}.png?${params.join('&')}`;
  url = url.slice(0, Math.min(url.length, 8192));

  return url;
}

