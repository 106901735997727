import { User } from '@aa/models/user';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, OperatorFunction } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

export const authorizationInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthenticationService);

  const isLoggedIn = auth.isLoggedIn();
  const isOauthRequest = req.url.match(/oauth/);

  if (isLoggedIn && !isOauthRequest) {
    return authorizeRequestAndHandle(auth, req, next)
      .pipe(
        catchAuthErrorAndRefresh(auth, req, next),
        handleResponse(auth),
      );
  }

  return next(req).pipe(handleResponse(auth));
};

function authorizeRequestAndHandle(
  auth: AuthenticationService,
  request: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> {
  return auth.tryAccessToken().pipe(
    switchMap((token) => {
      let authedRequest = request;
      if (token) {
        authedRequest = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${token}`),
        });
      }

      return next(authedRequest);
    })
  );
}

function catchAuthErrorAndRefresh(
  auth: AuthenticationService,
  request: HttpRequest<any>,
  next: HttpHandlerFn,
): OperatorFunction<HttpEvent<any>, HttpEvent<any> | Observable<HttpEvent<any>>> {
  return catchError<HttpEvent<any>, Observable<HttpEvent<any>>>((error) => {
    if (error instanceof HttpErrorResponse && (error.status == 401 || error.status == 403)) {
      return auth.refresh().pipe(switchMap(() => authorizeRequestAndHandle(auth, request, next)));
    }

    throw error;
  });
}


function handleResponse(auth: AuthenticationService): MonoTypeOperatorFunction<HttpEvent<any>> {
  return tap<HttpEvent<any>>((event) => {
    if (!(event instanceof HttpResponse)) return;

    const response: HttpResponse<any> = event;
    if (response.status === 401 && !response.url.endsWith('users/self')) {
      // TODO: How to dynamically tell this garbage what to do?
      // this.router.navigate(['login'], { replaceUrl: true });
    }

    if (response.status === 200 && !!auth.userSnapshot) {
      const matcher = new RegExp(`\/v1\/users\/(?:self|${auth.userSnapshot.id})$`);
      if (response.url.match(matcher)) {
        const user = response.body as User;
        if (!!user) {
          auth.setUser(user);
        }
      }
    }
  });
}
